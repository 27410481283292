import fpCollect from 'fpcollect';
import fpscanner from 'fpscanner';
import s from './sha256.js';

/**
 * samostatny puppeteer check
 * inspirace https://github.com/digitalhurricane-io/puppeteer-detection-100-percent
*/
const pptrCheck = new Promise((res, rej) => {
  const checkErr = () => {
    let err = new Error('check.err');

    const stck = err.stack.toString();
//console.log('err', stck);

    const rslt = {
      name: 'PPTR_CHECK',
      consistent: stck.includes('puppeteer') ? 1 : 3,
      data: {}
    };
    res(rslt);
  };

  const overrideFunction = ({obj, propName}) => {
    obj[propName] = (function (orig) {
      return function () {
        checkErr();

        let args = arguments;
        let value = orig.apply(this, args);

        return value;
      };

    }(obj[propName]));
  };

  overrideFunction({
      propName: 'querySelector',
      obj: document
  });
});

/**
 * playwright stealh detekce
 * zalozena na principu, ze WebGLRenderingContext.prototype.getParameter je prepsan jako Proxy s odlisnymi parametry enumerable a writable (najednou jsou false)
*/
const plwrCheck = new Promise((res, rej) => {

  const isRewritten = obj => {
    try {
      const desc = Object.getOwnPropertyDescriptor(WebGLRenderingContext.prototype, 'getParameter');
      return (!desc.writable || !desc.enumerable) ? 1 : 3;
    } catch (error) {
      return 2;
    };
    return 3;
  };

  const rslt = {
    name: 'PLWR_CHECK',
    consistent: isRewritten(WebGLRenderingContext.prototype.getParameter),
    data: {}
  };

  res(rslt);
});

/**
 * bot detection
*/
const b = () => {

  return Promise.all([
    fpCollect.generateFingerprint(),
    pptrCheck,
    plwrCheck
  ])
  .then(([fingerprint, pptr_check, plwr_check]) => {
    const res = Object.values(fpscanner.analyseFingerprint(fingerprint));
    res.push(pptr_check, plwr_check);
//console.log('b2 analyse', pptr_check, res);
    const failed = res.filter(({consistent}) => consistent === 1);
    const unsure = res.filter(({consistent}) => consistent === 2);

//TODO vypisovat ty chyby nejak kodovane, aby utocnik nedostal napovedu, co ma blbe
    if (failed.length > 0 || unsure.length > 2) {
      //pokud je pouze 1 failed a je to MQ_SCREEN, poustime dal - je to nejcastejsi problem
      if (failed.length == 1 && failed[0].name == 'MQ_SCREEN') return true;

      //BOT DETECTION FAIL FOUND
      const f = failed;
      const u = unsure;
      const err = {msg: 'B.D.F', f, u};
      //vypiseme do console, aby to pripadne user mohl reportovat
      console.log(err);
      //posleme to beaconem a zalogujeme
      navigator.sendBeacon('/b.d.f/', JSON.stringify({f, u}));
      throw err;
    };
    return true;
  });
};

/**
 * dela sha-256 HMAC digest
 * @param msg - message kterou chcem hmac-ovat
 * @param key - klic, kterym chceme hmac-ovat; defaultni mame primo ve funkci
 * @param binary - object; rika, jestli vstupy (msg, key) jsou binarni (blob)
 *   resp. vystup (dgt) ma byt binarni (ArrayBuffer)
 *   pokud neni binarni, jedna se o hex
 * @returns Promise, ktera resolvuje s vyslednym digestem - ArrayBuffer (binary == true) nebo hexa string (binary == false)
*/
const d = (
  msg, 
  key = undefined, 
  binary = {msg : false, key : false, dgt : false}, 
  botDetection = true
) => {
  const key_TextDecoder = 'Text' + 'Decent'.substring(0,3) + 'modern'.substring(1,5);
  const key_utf8 = 'put'.substring(1,3) + 'f' + '-' + 8;
  const key_decode = key_TextDecoder.substring(4, 10).toLowerCase();
  const key_Uint8Array = 'U' + 'INT'.toLowerCase() + 8 + 'Array';

  const charcodesToString = arr => new window[key_TextDecoder](key_utf8)[key_decode](new window[key_Uint8Array](arr));

  const key_unescape = charcodesToString([117, 110, 101, 115, 99, 97, 112, 101]);
  const key_arrayBuffer = charcodesToString([97, 114, 114, 97, 121, 66, 117, 102, 102, 101, 114]);
  const key_encodeURIComponent = charcodesToString([101, 110, 99, 111, 100, 101, 85, 82, 73, 67, 111, 109, 112, 111, 110, 101, 110, 116]);
  const key_charCodeAt = charcodesToString([99, 104, 97, 114, 67, 111, 100, 101, 65, 116]);
  const key_hmac = charcodesToString([72, 77, 65, 67]);
  const key_sha256 = charcodesToString([83, 72, 65, 45, 50, 53, 54]);

  //default hmac key
  //'bjIsOZnTlI5ktxO7uGTmJyfxBIonEtrwFPIaePVPPcAKj9YNlrm2TItjePlPySkD'

  if (key === undefined) {
    key = charcodesToString([98, 106, 73, 115, 79, 90, 110, 84, 108, 73, 53, 107, 116, 120, 79, 55, 117, 71, 84, 109, 74, 121, 102, 120, 66, 73, 111, 110, 69, 116, 114, 119, 70, 80, 73, 97, 101, 80, 86, 80, 80, 99, 65, 75, 106, 57, 89, 78, 108, 114, 109, 50, 84, 73, 116, 106, 101, 80, 108, 80, 121, 83, 107, 68]);
  };

  // * @param str - hex string
  // * @returns arrayBuffer
  const getBufferFromHex = str => new window[key_Uint8Array]([...window[key_unescape](window[key_encodeURIComponent](str))].map(c => c[key_charCodeAt](0))).buffer;

  // * @param buffer - ArrayBuffer
  // * @returns hex
  const getHexFromBuffer = buffer => [...new window[key_Uint8Array](buffer)].map(b => b.toString(16).padStart(2, '0')).join('');

  // * @param str - hex string (binary == false) nebo blob (binary == true)
  // * @param binary boolean
  // * @returns promise, co resolvuje s Uint8Array()
  const getUint8Array = (str, binary = false) => (
    binary ? str[key_arrayBuffer]() : window.Promise.resolve(getBufferFromHex(str))
  ).then(buffer => new window[key_Uint8Array](buffer));

  //const stringToCharcodes = string => new window.TextEncoder().encode(string);
  //console.log(stringToCharcodes("bjIsOZnTlI5ktxO7uGTmJyfxBIonEtrwFPIaePVPPcAKj9YNlrm2TItjePlPySkD"));
//console.log('d, before b');

  return (botDetection ? b() : Promise.resolve(true))
  .then(() => {
//console.log('func d -> b -> then');
    return window.Promise.all([
      getUint8Array(key, binary.key),
      getUint8Array(msg, binary.msg)
    ])
  })
  .then(([keyBytes, msgBytes]) => {
    //const H = sha256.hmac.create(keyBytes);
//console.log('func d -> b -> then -> then');
    const H = s.h.create(keyBytes);
    H.update(msgBytes);
    const signature = H[key_arrayBuffer]();
//console.log('sig', signature);
    return binary.dgt ? signature : getHexFromBuffer(signature);
  });
};

//digest BEZ bot detection
const d0 = (msg) => {
  return d(msg, undefined, {msg : false, key : false, dgt : false}, false);
}

/*
const d2 = (msg, key = undefined, binary = {msg : false, key : false, dgt : false}) => {

  const key_TextDecoder = 'Text' + 'Decent'.substr(0,3) + 'modern'.substr(1,4);
  const key_utf8 = 'put'.substr(1,2) + 'f' + '-' + 8;
  const key_decode = key_TextDecoder.substr(4, 6).toLowerCase();
  const key_Uint8Array = 'U' + 'INT'.toLowerCase() + 8 + 'Array';

  const charcodesToString = arr => new window[key_TextDecoder](key_utf8)[key_decode](new window[key_Uint8Array](arr));

  //default key
  //'bjIsOZnTlI5ktxO7uGTmJyfxBIonEtrwFPIaePVPPcAKj9YNlrm2TItjePlPySkD'
  if (key === undefined) {
    key = charcodesToString([98, 106, 73, 115, 79, 90, 110, 84, 108, 73, 53, 107, 116, 120, 79, 55, 117, 71, 84, 109, 74, 121, 102, 120, 66, 73, 111, 110, 69, 116, 114, 119, 70, 80, 73, 97, 101, 80, 86, 80, 80, 99, 65, 75, 106, 57, 89, 78, 108, 114, 109, 50, 84, 73, 116, 106, 101, 80, 108, 80, 121, 83, 107, 68]);
  };

  const key_unescape = charcodesToString([117, 110, 101, 115, 99, 97, 112, 101]);
  const key_arrayBuffer = charcodesToString([97, 114, 114, 97, 121, 66, 117, 102, 102, 101, 114]);
  const key_encodeURIComponent = charcodesToString([101, 110, 99, 111, 100, 101, 85, 82, 73, 67, 111, 109, 112, 111, 110, 101, 110, 116]);
  const key_charCodeAt = charcodesToString([99, 104, 97, 114, 67, 111, 100, 101, 65, 116]);

  // * @param str - hex string (binary == false) nebo blob (binary == true)
  // * @param binary vv
  // * @returns promise, co resolvuje s Uint8Array()
  const getUtf8Bytes = (str, binary = false) => (
    binary ? str[key_arrayBuffer]() : window.Promise.resolve([...window[key_unescape](window[key_encodeURIComponent](str))].map(c => c[key_charCodeAt](0)))
  ).then(bufferOrArray => new window[key_Uint8Array](bufferOrArray));

  const getHexFromArrayBuffer = buffer => [...new window[key_Uint8Array](buffer)].map(b => b.toString(16).padStart(2, '0')).join('');

  const key_crypto = charcodesToString([99, 114, 121, 112, 116, 111]);
  const key_subtle = charcodesToString([115, 117, 98, 116, 108, 101]);
  const key_importKey = charcodesToString([105, 109, 112, 111, 114, 116, 75, 101, 121]);
  const key_hmac = charcodesToString([72, 77, 65, 67]);
  const key_sha256 = charcodesToString([83, 72, 65, 45, 50, 53, 54]);
  const key_sign = charcodesToString([115, 105, 103, 110]);
  const key_raw = charcodesToString([114, 97, 119]);

  //const stringToCharcodes = string => new window.TextEncoder().encode(string);
  //console.log(stringToCharcodes("bjIsOZnTlI5ktxO7uGTmJyfxBIonEtrwFPIaePVPPcAKj9YNlrm2TItjePlPySkD"));

  //webCrypto object
  const crypto = window[key_crypto];

  const keyBytes = getUtf8Bytes(key, binary.key);
  const msgBytes = getUtf8Bytes(msg, binary.msg);

  return window.Promise.all([
    getUtf8Bytes(key, binary.key),
    getUtf8Bytes(msg, binary.msg)
  ])
  .then(([keyBytes, msgBytes]) => crypto[key_subtle][key_importKey](
      key_raw, keyBytes, { name: key_hmac, hash: key_sha256 }, true, [key_sign]
    ).then(
      cryptoKey => crypto[key_subtle][key_sign](key_hmac, cryptoKey, msgBytes)
    ).then(
      sig => binary.dgt ? sig : getHexFromArrayBuffer(sig)
    )
  );
};
*/

export {d, d0};
